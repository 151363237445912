root {
    width: 100vh;
    height: 100vh;
}

a:visited { text-decoration: none; color:inherit; }
a:hover { text-decoration: none; color:inherit; }
a:focus { text-decoration: none; color:inherit; }
a:hover, a:active { text-decoration: none; color:inherit }

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100%;
    background-color: #f2efed;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Reset scrollbar width and color */
::-webkit-scrollbar {
    width: 0;
}

/* For Firefox */
* {
    scrollbar-width: 0;
    scrollbar-color: auto;
}